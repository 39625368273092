import React, { FC } from 'react';
import { GrLink } from 'react-icons/gr';
import { generatePageSectionSlug } from '../../utils/url';
import * as styles from './generic-article.module.scss';

export interface GenericArticleProps {
	title?: string;
	disableAnchor?: boolean;
}

export const GenericArticle: FC<GenericArticleProps> = ({ title, children, disableAnchor = true }) => {
	const slug = title && !disableAnchor ? generatePageSectionSlug(title) : undefined;

	return (
		<section className={styles.root} id={slug && !disableAnchor ? slug : undefined}>
			{title && (
				<h2>
					{slug && (
						<a href={`#${slug}`} target="_self">
							<GrLink />
						</a>
					)}{' '}
					{title}{' '}
				</h2>
			)}
			{children}
		</section>
	);
};

GenericArticle.displayName = 'GenericArticle';
