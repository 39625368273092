import { graphql, PageProps } from 'gatsby';
import React, { CSSProperties, FC } from 'react';

import type { ProjectSchema } from '../../schemas/project';
import { ContentConstraint } from '../../components/ContentConstraint/ContentConstraint';
import { Stage } from '../../components/Stage/Stage';
import { TableArticle } from '../../components/TabelArticle/TableArticle';
import { Configurator } from '../../fragments/Configurator/Configurator';
import { Layout } from '../../fragments/Layout/Layout';
import { LinkButton } from '../../components/Button/Button';
import { GenericArticle } from '../../components/GenericArticle/GenericArticle';
import { SEO } from '../../components/SEO/SEO';

export const query = graphql`
	query ($slug: String) {
		projectsJson(slug: { eq: $slug }) {
			name
			category
			url
			role
			stack
			contractor {
				name
				url
			}
			details {
				title
				subtitle
				sector
				brandColor
			}
		}
	}
`;

type ProjectDetailPageProps = PageProps<{
	projectsJson: ProjectSchema;
}>;

const ProjectDetailPage: FC<ProjectDetailPageProps> = ({ data, uri }) => {
	const { projectsJson } = data;
	const { name, url, category, contractor, details, role, stack } = projectsJson;

	const style = details.brandColor
		? ({
				'--color-primary-yellow': details.brandColor,
				'--color-primary-turquoise': details.brandColor,
				'--color-primary-blue': details.brandColor,
				'--color-primary-pink': details.brandColor,
		  } as CSSProperties)
		: undefined;

	return (
		<Configurator>
			<SEO title={`Project: ${name}`} url={uri} description={details.subtitle} type="article" />
			<Layout style={style}>
				<Stage title={name} lead={details.title}>
					<LinkButton url={url}>View Project</LinkButton>
				</Stage>
				<ContentConstraint>
					<GenericArticle title="Description">
						<p>{details.subtitle}</p>
					</GenericArticle>
				</ContentConstraint>
				<ContentConstraint>
					<TableArticle
						title="Project Information"
						data={[
							['Category', category],
							['Stack', stack.join(', ')],
							['Role', role],
							['Contractor', contractor.name],
						]}
					/>
				</ContentConstraint>
			</Layout>
		</Configurator>
	);
};

ProjectDetailPage.displayName = 'ProjectDetailPage';

export default ProjectDetailPage;
