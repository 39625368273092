import React, { FC } from 'react';
import { GenericArticle, GenericArticleProps } from '../GenericArticle/GenericArticle';
import * as styles from './table-article.module.scss';

export interface TableArticleProps extends GenericArticleProps {
	head?: Array<string | number>;
	data?: Array<Array<string | number>>;
}

export const TableArticle: FC<TableArticleProps> = ({ title, head, data, disableAnchor = true }) => (
	<GenericArticle title={title} disableAnchor={disableAnchor}>
		<table className={`${styles.table} ${!!head ? '' : styles.noHead}`}>
			{head && (
				<thead className={styles.head}>
					<tr className={styles.row}>
						{head.map((entry, index) => (
							<th key={index} className={styles.cell}>
								{entry}
							</th>
						))}
					</tr>
				</thead>
			)}
			{data && (
				<tbody className={styles.body}>
					{data.map((rows, rowCount) => (
						<tr key={rowCount} className={styles.row}>
							{rows.map((entry, cellCount) => (
								<td key={`${rowCount}${cellCount}`} className={styles.cell}>
									{entry}
								</td>
							))}
						</tr>
					))}
				</tbody>
			)}
		</table>
	</GenericArticle>
);

TableArticle.displayName = 'TableArticle';
